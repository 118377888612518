<template>
<div class="main_wrp">
    <NavBottom :checkNum="5" />

    <div class="tabtop">
        <span :class="acindx==0?'on':''" @click="showList(0)">全部</span>
        <span :class="acindx==1?'on':''" @click="showList(1)">有效订单</span>
        <span :class="acindx==3?'on':''" @click="showList(3)">售后/理赔</span>
        <span :class="acindx==2?'on':''" @click="showList(2)">已过期</span>
    </div>
    <div class="card_list">
        <UserPad />
        <template v-if="plist.length == 0">
            <div class="no-data">
                抱歉，当前服务器未查询到相关数据！！！
            </div>
        </template>
        <template v-else>
            <div class="card_wrp" v-for="(item,index) in plist" :key="index">
                <div class="card_main">
                    <div class="statusText">
                        <text v-if="item.Insurance_Reject==1">已退款</text>
                        <text v-else-if="item.Insurance_Status==1">已缴费</text>
                        <text v-else-if="item.Insurance_Status==0">未缴费</text>
                    </div>
                    <div class="card_tit">
                        {{item.Insurance_PolicyName}}
                    </div>
                    <div class="card_lit">
                        <text>被保人: </text>{{item.Insurance_VisitName}}
                    </div>
                    <div class="card_lit">
                        <text>金额: </text> &yen; {{item.Insurance_Money}} 元
                    </div>
                    <div class="card_lit">
                        <text>订单号: </text>{{item.Insurance_Number}}
                    </div>
                    <div class="card_lit">
                        <text>保障时间: {{item.exc_time}}
                        </text>
                    </div>
                </div>
                <div class="btnswrp">
                    <button v-if="item.Insurance_Status == 1" type="default" @click="showTip(item)">理赔</button>
                    <router-link v-if="item.Insurance_Status == 1 && (item.Insurance_Reject!=1&&item.Insurance_RefundRequest!=1)"  :to="'/insuranceOrderDetailInfo/'+item.Insurance_ID">保单详情</router-link>
                    <button type="warn" v-if="item.Insurance_Status == 1&&item.Insurance_RefundRequest == 0" @click="moneyBack(item)">申请退款</button>
                </div>
            </div>
            <div class="more-data" @click="loadmore(acindx)">
                加载更多
            </div>
        </template>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            status: 0,
            acindx: 0,
            pageindex: 1,
            gindx: 0,
            types: [],
            plist: [],
            member: ""
        };
    },
    created() {
		this.resetMeta()
        this.status = this.$route.params.statu;
        console.log("statu:", this.status);
        this.showList(this.status);
    },

    methods: {
		resetMeta() {
            const metas = document.querySelector('head').querySelector('meta[name=viewport]')
            metas.content = 'width=device-width, initial-scale=1, user-scalable=0'
        },
        showTip(item) {
            console.log(item.Insurance_ID);

            this.wpost({
                url: "/Ajax/Insurance/getinsurancemodel",
                data: {
                    id: item.Insurance_ID
                },
                succ: data => {
                    console.log("result:", data);
                    if (data.hasapplycourier > 0) {
                        // 跳转理赔申请页面

                        this.$router.push("/applyForm/" + item.Insurance_ID);
                    } else {
                        alert("请您直接拨打 " + (data.phone || 95519) + " 进行理赔申请");
                    }
                }
            });
        },
        moneyBack: function (item) {
            let name = item.InsuranceCourier_Name;
            let contect = item.InsuranceCourier_Phone;

            alert(
                `请电话联系${name}；电话${contect}，您的服务专员，为你办理退款事宜。`
            );

            return false;
        },
        showList: function (id) {
            let that = this;
            this.plist = [];
            this.acindx = id;
            this.pageindex = 1;
            this.wpost({
                url: "/Ajax/Insurance/getinsurancelist",
                data: {
                    status: id,
                    pagesize: 20,
                    pageindex: that.pageindex
                },
                succ: function (data) {
                    // console.log("result:",data)
                    that.plist = data.list.map(item => {
                        let exc_time = "";
                        if (item.Insurance_Status == 1) {
                            exc_time =
                                item.Insurance_EffectContent ||
                                item.Insurance_EffectStart + "-" + item.Insurance_EffectEnd;
                        } else {
                            exc_time =
                                item.Insurance_EffectContent || item.Supplement_EffectContent;
                        }

                        return {
                            ...item,
                            exc_time: exc_time
                        };
                    });
                    that.member = data.member;
                }
            });
        },
        loadmore: function (id) {
            let that = this;
            // console.log('loadmore data')
            this.wpost({
                url: "/Ajax/Insurance/getinsurancelist",
                data: {
                    status: id,
                    pagesize: 20,
                    pageindex: (that.pageindex += 1)
                },
                succ: function (data) {
                    if (data.list.length > 0) {
                        let templist = data.list.map(item => {
                            let exc_time = "";
                            if (item.Insurance_Status == 1) {
                                exc_time =
                                    item.Insurance_EffectContent ||
                                    item.Insurance_EffectStart + "-" + item.Insurance_EffectEnd;
                            } else {
                                exc_time =
                                    item.Insurance_EffectContent || item.Supplement_EffectContent;
                            }

                            return {
                                ...item,
                                exc_time: exc_time
                            };
                        });
                        that.plist = that.plist.concat(templist);
                    } else {
                        that.pageindex -= 1;
                        alert("暂无更多相关数据...")
                    }
                }
            });
            // console.log("pageindex:", that.pageindex)
        }
    }
};
</script>

<style>
#container {
    background: #f5f5f5;

}

.wtop {
    position: relative;
}

.wtop img {
    width: 100%;
}

.wtopIn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.wtips {
    position: absolute;
    top: 6px;
    right: 12px;
    color: #fff;
    font-size: 24rpx;
}

.statusText {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid #f0ad4e;
    box-shadow: 0 0 3px 1px #f0ad4e;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    right: 6px;
    bottom: 12px;
}

.card_main .statusText text {
    margin: 0;
    color: #f97a0a;
    font-size: 11px;
}

.card_main {
    position: relative;
}

.userInfo {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    color: #fff;
}

.wimg {
    width: 70px;
    height: 70px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.wimg img {
    width: 70px;
    height: 70px;
}

.wtext {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.wtext text {
    font-size: 25rpx;
}

.warr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    color: #fff;
}

.main_wrp {
    position: relative;
}

.top_head {
    height: 51px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f97a0a;
}

.btnswrp {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    border: 1px solid #f0f0f0;
}

.btnswrp button,.btnswrp a {

    background: none;
    border: none;
    outline: none;
    box-shadow: none;
	padding: 2px 6px;
    color: #888;
    border: 1px solid #888;
    border-radius: 20px;
    font-size: 13px;
}

.card_wrp button {
    margin: 10px 5px;
}

.card_list {
    padding-top: 36px;
    box-sizing: border-box;
}

.tabtop {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #fff;
}

.tabtop span {
    display: block;
    width: 25%;
    color: #3f536e;
    font-size: 14px;
    padding: 8px 0;
    text-align: center;
    border: none;
    background-color: none;
}

.tabtop span.on {
    color: #f97a0a;
}

.tabtop span:active {
    background-color: #ececec;
}

.card_intros {
    font-size: 32rpx;
    line-height: 1.6;
    padding: 10px;
    font-weight: bold;
}

.card_wrp {
    margin: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 6px;
}

.card_lit {
    padding: 2px 10px;
}

.card_tit {
    color: #333;
    padding: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    border-bottom: 1px solid #f0f0f0;
    overflow: hidden;
    font-size: 15px;
    margin: 6px 0;
}

.card_main {
    font-size: 14px;
    color: #e91f2f;
}

.card_main text {
    margin-right: 6px;
    font-size: 13px;
    color: #333;
}

.nav_county {
    width: 150upx;
    text-align: center;
    border-right: 1px solid #dedede;
    box-sizing: border-box;
}

.nav_county text {
    display: block;
    padding: 12px 0;
    font-size: 14px;
    background-color: #ececec;
    color: #888;
    border: 1px solid #dedede;
    border-top: none;
    border-right: none;
}

.nav_county text.ac {
    color: #f97a0a;
    background: #fff;
}
</style>
